.overlay-wrapper {
    position: relative;
}

.overlay-wrapper img {
    position: inherit;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

@media (min-width: 1920px) {
    .overlay-wrapper img {
        width: 100%;
    }
}

.overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    text-align: center;
}
